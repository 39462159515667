import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikField from "src/components/FormikField/FormikField";
import TwinButton from "src/components/TwinButton";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import { useEffect, useState } from "react";
import { getData } from "src/actions/getData";
import { updateData } from "src/actions/updateData";
export default function EditGroup() {

    const [data, setData] = useState()

    const navigate = useNavigate();
    const pathname = useGetCurrentURL();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    useEffect(() => {
        getData(setData, `/groups/${id}`);
    }, [])

    return (
        <>
            <PageHeader
                title="ویرایش گروه"
            />
            <WhiteBoard>
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: data?.name,
                        description: data?.description,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        updateData(`groups/${id}`, {
                            name: values?.name,
                            description: values?.description,
                        }, () => {
                            navigate(pathname.slice(0, pathname.search("/edit")));
                        })
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl p-10 px-20">
                                <div className="flex gap-10 mt-10">
                                    <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="name"
                                            label="عنوان"
                                            placeholder="عنوان گروه را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="description"
                                            label="توضیحات"
                                            placeholder="توضیحات گروه را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <TwinButton
                                    rejectHandle={
                                        () => {
                                            navigate(pathname.slice(0, pathname.search("/edit")));
                                        }
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </WhiteBoard>
        </>
    )
}