import { useEffect, useState } from 'react';
import Table from 'src/components/Table'
import { get } from 'src/services/HttpClient';
import { PiEyeDuotone } from "react-icons/pi";
import { PiTrashDuotone } from "react-icons/pi";
import { PiPencilDuotone } from "react-icons/pi";
import PageHeader from 'src/components/PageHeader';
import { useNavigate } from 'react-router-dom';
import useGetCurrentURL from 'src/utils/useGetCurrentURL';
import DeleteModal from 'src/components/DeleteModal';
var moment = require("jalali-moment");



export default function Contacts() {
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const pathname = useGetCurrentURL();
    const navigate = useNavigate();

    const columns = [
        // {
        //     id: "id",
        //     cell: info => info.getValue(),
        //     header: "شناسه",
        //     footer: info => info.column.id,
        // },
        {
            id: "firstName",
            cell: info => info.getValue() ? info.getValue() : "---",
            header: "نام",
            footer: info => info.column.firstName,
        },
        {
            id: "lastName",
            cell: info => info.getValue() ? info.getValue() : "---",
            header: "نام خانوادگی",
            footer: info => info.column.lastName,
        },
        {
            id: "email",
            cell: info => info.getValue() ? info.getValue() : "---",
            header: "ایمیل",
            footer: info => info.column.email,
        },
        {
            id: "username",
            cell: info => info.getValue() ? info.getValue() : "---",
            header: "نام کاربری",
            footer: info => info.column.username,
        },
        {
            id: "phone",
            cell: info => info.getValue() ? info.getValue() : "---",
            header: "موبایل",
            footer: info => info.column.phone,
        },
        {
            id: "groups",
            cell: info => info.getValue().map((item) => {
                return item.name
            }).join("، "),
            header: "گروه",
            footer: info => info.column.phone,
        },
        {
            id: "joinDate",
            cell: (info) => (
                <>
                    {info.getValue()
                        ? moment
                            .from(info.getValue(), "en", "YYYY-MM-DD HH:mm:ss")
                            .locale("fa")
                            .format("jYYYY/jMM/jDD HH:mm")
                        : "---"}
                </>
            ),
            header: "تاریخ عضویت",
            footer: info => info.column.joinDate,
        },
        {
            id: "leaveDate",
            cell: (info) => (
                <>
                    {info.getValue()
                        ? moment
                            .from(info.getValue(), "en", "YYYY-MM-DD HH:mm:ss")
                            .locale("fa")
                            .format("jYYYY/jMM/jDD HH:mm")
                        : "---"}
                </>
            ),
            header: "تاریخ ترک",
            footer: info => info.column.leaveDate,
        },
        {
            id: "id",
            cell: info => (
                <div className='flex gap-2'>
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            navigate(
                                `/app/contact-management/contact/edit?id=${info.getValue()}`
                            );
                        }}
                    >
                        <PiPencilDuotone className='' />
                    </div>
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            setSelectedRow(info.getValue())
                            setActive(true)
                        }}
                    >
                        <PiTrashDuotone className='' />
                    </div>

                </div>
            ),
            header: "عملیات",
            footer: info => info.column.id,
            enableColumnFilter: false
        },
    ];

    useEffect(() => {
        getData();
    }, [reload]);

    async function getData() {
        const result = await get("/contacts");
        if (result && result.success) {
            setData(result.body)
        }
    }

    return (
        <>
            <PageHeader
                title="مخاطبان"
                btnTitle="افزودن مخاطب جدید"
                handleClick={() => {
                    navigate(pathname + "/add");
                }}
            />
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف مخاطب"
                id={selectedRow}
                url="/contacts"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    )
}