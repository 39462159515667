import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikField from "src/components/FormikField/FormikField";
import { useEffect, useState } from "react";
import { get } from "src/services/HttpClient";
import TwinButton from "src/components/TwinButton";
import { useLocation, useNavigate } from "react-router-dom";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import MultiTab from "src/components/MultiTab";
import Tab from "src/components/MultiTab/Tab";
import { IoIosBarcode } from "react-icons/io";
import { BiMailSend } from "react-icons/bi";
import TextEditor from "src/components/TextEditor";
import PatternMessage from "./PatternMessage";
import Hintbox from "src/components/Hintbox";
import Space from "src/components/Space";
import { validate } from "./Validate";
import { submit } from "./Submit";
var moment = require('jalali-moment');

export default function AddMessage() {
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const cid = params.get("cid");

    const [campaignOptions, setCampaignOptions] = useState([])
    const [campType, setCampType] = useState()
    const [text, setText] = useState("")
    const [vars, setVars] = useState([])
    const [messageType, setMessageType] = useState("text")

    const getCampOptions = async () => {
        let result = await get("/campaigns");
        if (result.body) {
            setCampaignOptions(result.body.map(function (single) {
                return {
                    value: single.id,
                    label: single.name
                }
            }))
        }
    }

    const getCampType = async () => {
        let result = await get(`/campaigns/${cid}`);
        if (result.body) {
            setCampType(result?.body?.type)
        }
    }

    useEffect(() => {
        if (cid) {
            getCampType();
        }
        getCampOptions();
    }, [])

    useEffect(() => {
        console.log(text);
    }, [text])


    const handleCampChange = async (selectedOption) => {
        let campId = selectedOption?.value;
        if (!campId) {
            setCampType(null)
            return;
        }
        let result = await get(`/campaigns/${campId}`);
        if (!result.body) {
            return
        }
        setCampType(result.body?.type)
    }

    return (
        <>
            <PageHeader
                title="افزودن پیام"
            />
            <Formik
                initialValues={{
                    name: "",
                    campaignId: parseInt(cid),
                    messageTime: "",
                    leastDay: "",
                    day: "",
                    hour: "",
                    minute: "",
                    second: "",
                    patternCode: "",
                }}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                    if (!validate(values, campType, messageType, text)) {
                        return
                    }
                    submit(values, campType, messageType, text, vars);

                    if (cid) {
                        navigate(`/app/message-management/message/?cid=${cid}`)
                    } else {
                        navigate(`/app/message-management/message`)
                    }
                }}
            >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                        <WhiteBoard>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl px-10 py-5">
                                <div className="flex gap-10">
                                    <div className="flex flex-col xl:grid gap-5 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="name"
                                            label="نام پیام (مخاطبان این نام را نخواهند دید)"
                                            placeholder="یک نام برای پیام انتخاب کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            type="select"
                                            inputName="campaignId"
                                            label="کمپین"
                                            placeholder="کمپینی که پیام به آن متعلق است را انتخاب کنید..."
                                            // info="این کمپین روی چه گروه‌هایی اجرا می‌شود"
                                            errors={errors}
                                            options={campaignOptions}
                                            values={values}
                                            disabled={cid ? true : false}
                                            value={values.campaignId}
                                            setFieldValue={setFieldValue}
                                            additionalAction={(selectedOption) => {
                                                handleCampChange(selectedOption);
                                            }}
                                        />
                                        {
                                            campType == "fixed"
                                            &&
                                            <>
                                                <FormikField
                                                    type="datepicker"
                                                    inputName="messageTime"
                                                    label="زمان ارسال"
                                                    placeholder="مشخص کنید این پیام در چه تاریخ و چه ساعتی برای مخاطبان شما ارسال شود"
                                                    // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                                    errors={errors}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                />
                                                <FormikField
                                                    type="number"
                                                    inputName="leastDay"
                                                    label="حداقل روز گذشته از عضویت"
                                                    placeholder="حداقل چند روز از عضویت شخص گذشته باشد تا این پیام برای او ارسال شود؟"
                                                    // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                                    errors={errors}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                                {
                                    campType == "camp"
                                    &&
                                    <div className="flex flex-col gap-3">
                                        <span>
                                            مشخص کنید این گام در چه زمانی بعد از عضویت افراد جدید به کمپین برای آن‌ها ارسال شود؟
                                        </span>
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <span>
                                                    پس از
                                                </span>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <Field
                                                    className="w-[70px] p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                                    type="number"
                                                    name="day"
                                                    min={0}
                                                />
                                                <span>
                                                    روز
                                                </span>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <Field
                                                    className="w-[70px] p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                                    type="number"
                                                    name="hour"
                                                    min={0}
                                                />
                                                <span>
                                                    ساعت
                                                </span>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <Field
                                                    className="w-[70px] p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                                    type="number"
                                                    name="minute"
                                                    min={0}
                                                />
                                                <span>
                                                    دقیقه
                                                </span>
                                            </div>
                                            <div className="flex gap-1 items-center">
                                                <Field
                                                    className="w-[70px] p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                                    type="number"
                                                    name="second"
                                                    min={0}
                                                />
                                                <span>
                                                    ثانیه
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    بعد از عضویت شخص ارسال شود
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </WhiteBoard>
                        <WhiteBoard className="mt-5">
                            <MultiTab justify="center" type={3} additionalAction={setMessageType}>
                                <Tab title="پیام عادی" code="text" icon={<BiMailSend />}>
                                    <Hintbox text="پیام‌های عادی به صورت پیامک تبلیغاتی ارسال می‌شوند و ممکن است همراه با تاخیر ارسال شوند و به گوشی مخاطبانی که پیامک‌های تبلیغاتی خود را مسدود کرده‌اند نمی‌رسند" />
                                    <Space height={15} />
                                    <TextEditor value={text} handleOnChange={setText} />
                                </Tab>
                                <Tab title="پیام پترن" code="pattern" icon={<IoIosBarcode />}>
                                    <Hintbox text="پیام‌های پترن در پنل پیامکی شما تعریف می‌شوند و به سرعت ارسال می‌شوند و به گوشی تمامی مخاطبین خواهند رسید" />
                                    <Space height={15} />
                                    <PatternMessage vars={vars} setVars={setVars} />
                                </Tab>
                            </MultiTab>
                        </WhiteBoard>
                        <TwinButton
                            acceptTitle="ذخیره پیام"
                            rejectHandle={
                                () => {
                                    navigate(pathname.slice(0, pathname.search("/add")));
                                }
                            }
                        />
                    </Form>
                )}
            </Formik>
        </>
    )
}