import { useEffect, useState } from "react";
import { getData } from "src/actions/getData";
import MiniInfoButton from "src/components/MiniInfoButton";
import PageHeader from "src/components/PageHeader";
import Table from "src/components/Table";
var moment = require('jalali-moment');

export default function Reports() {
    const [data, setData] = useState()
    useEffect(() => {
        getData(setData, "/executions")
    }, [])
    const columns = [
        // {
        //     id: "id",
        //     cell: info => info.getValue(),
        //     header: "شناسه",
        //     footer: info => info.column.id,
        // },
        {
            id: "message",
            cell: info => info.getValue()?.name ?? <MiniInfoButton text="پیام پاک شده" color="red" />,
            header: "پیام",
            footer: info => info.column.name,
        },
        {
            id: "contact",
            cell: info => info.getValue()?.firstName + " " + info.getValue()?.lastName ?? <MiniInfoButton text="مخاطب پاک شده" color="red" />,
            header: "مخاطب",
            footer: info => info.column.description,
        },
        {
            id: "contact",
            cell: info => info.getValue()?.phone ?? <MiniInfoButton text="مخاطب پاک شده" color="red" />,
            header: "موبایل",
            footer: info => info.column.description,
        },
        {
            id: "executionTime",
            cell: info => moment.unix(info.getValue() / 1000).locale('fa').format('jYYYY/jM/jD HH:mm'),
            header: "زمان ارسال",
            footer: info => info.column.description,
        },
        {
            id: "status",
            cell: info => info.getValue() ? <MiniInfoButton text="ارسال شده" color="green" /> : <MiniInfoButton text="در صف ارسال" color="blue" />,
            header: "وضعیت ارسال",
            footer: info => info.column.description,
        },
    ];
    return (
        <>
            <PageHeader
                title="گزارش ارسال‌ها"
            />
            <Table data={data} columns={columns} />

        </>
    )
}