import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addData } from "src/actions/addData";
import FormikField from "src/components/FormikField/FormikField";
import TwinButton from "src/components/TwinButton";
import { get, post } from "src/services/HttpClient";
import useGetCurrentURL from "src/utils/useGetCurrentURL";

export default function AddTimedMessage() {
    const [groupOptions, setGroupOptions] = useState([])
    const pathname = useGetCurrentURL();
    const navigate = useNavigate();

    const getOrgs = async () => {
        let result = await get("/groups");
        if (result.body) {
            setGroupOptions(result.body.map(function (single) {
                return {
                    value: single.id,
                    label: single.name
                }
            }))
        }
    }


    useEffect(() => {
        getOrgs();
    }, [])
    return (
        <>
            <PageHeader
                title="افزودن کمپین جدید"
            />
            <WhiteBoard>
                <Formik
                    initialValues={{
                        name: "",
                        description: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        addData("/campaigns", {
                            name: values.name,
                            description: values.description,
                            type: "fixed",
                            groupId: values.groupId
                        }, () => {
                            navigate(pathname.slice(0, pathname.search("/add")));
                        })
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl px-10 py-5">
                                <div className="flex gap-10">
                                    <div className="flex flex-col xl:grid gap-5 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="name"
                                            label="عنوان"
                                            placeholder="عنوان را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="description"
                                            label="توضیحات"
                                            placeholder="توضیحات را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            type="multiselect"
                                            inputName="groupId"
                                            label="گروه"
                                            placeholder="گروه‌های مورد نظر را انتخاب کنید..."
                                            info="این کمپین روی چه گروه‌هایی اجرا می‌شود"
                                            errors={errors}
                                            options={groupOptions}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                                <TwinButton
                                    acceptTitle="افزودن کمپین جدید"
                                    rejectHandle={
                                        () => {
                                            navigate(pathname.slice(0, pathname.search("/add")));
                                        }
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </WhiteBoard>
        </>
    )
}