import FormikField from "src/components/FormikField";
import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { notify } from "src/services/Notification/Notification";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { postData } from "src/actions/postData";
import { getData } from "src/actions/getData";

export default function Ippanel() {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        getData(setData, "/settings")
    }, [])


    return (
        <>
            <PageHeader
                title="تنظیمات پنل پیامک"
            />
            <WhiteBoard>
                <Formik
                    enableReinitialize
                    initialValues={{
                        smsUser: data?.smsUser,
                        smsPass: data?.smsPass,
                        smsFrom: data?.smsFrom,
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (values.smsUser) {
                            postData("/settings", {
                                key: "smsUser",
                                value: values.smsUser
                            })
                        }
                        if (values.smsPass) {
                            postData("/settings", {
                                key: "smsPass",
                                value: values.smsPass
                            })
                        }
                        if (values.smsFrom) {
                            postData("/settings", {
                                key: "smsFrom",
                                value: values.smsFrom
                            })
                        }
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl p-10 px-20">
                                <div className="flex gap-10 mt-10">
                                    <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="smsUser"
                                            label="نام کاربری پنل پیامک"
                                            placeholder="نام کاربری پنل پیامک را در این قسمت وارد کنید"
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="smsPass"
                                            label="رمز عبور پنل پیامک"
                                            placeholder="رمز عبور ورود به پنل پیامک را در این قسمت وارد کنید"
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="smsFrom"
                                            label="شماره ارسال کننده پیامک"
                                            placeholder="شماره‌ای که پیامک‌ها از آن ارسال می‌شوند را مشخص کنید"
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className={`p-4 gap-5 justify-center w-full items-center flex flex-col lg:flex lg:flex-row`}>
                                    <button
                                        type="submit"
                                        className={`w-[190px] flex justify-center items-center opacity-90 hover:opacity-100 transition-all ease-in-out duration-200 text-sm text-white bg-primary-color px-6 py-2 rounded-md xl:px-8 xl:py-3`}
                                    >
                                        {
                                            isLoading
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25" /><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="1.125s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>
                                                :
                                                <span>ذخیره تغییرات</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </WhiteBoard>
        </>
    )
}