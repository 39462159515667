export function milisecondsToDhms(milisecond) {
    let seconds = Number(milisecond / 1000);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + " روز، " : "";
    var hDisplay = h >= 0 ? h + " ساعت، " : "";
    var mDisplay = m >= 0 ? m + " دقیقه، " : "";
    var sDisplay = s >= 0 ? s + " ثانیه" : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}
