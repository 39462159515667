import { useContext } from "react";
import userContext from "src/context/userContext";
import { Navigate } from "react-router-dom";

export default function PermissionBasedComponent({ permission, children, isFull = false }) {
    let { user } = useContext(userContext)
    console.log(user);
    // let userPermissions = user?.type ? JSON.parse(user.role.permissions) : [];
    let userPermissions = ["SUPER_USER_PERMISSION"]
    return (
        <>
            {userPermissions.find((obj) => {
                return obj === "SUPER_USER_PERMISSION";
            }) ? (
                children
            ) : userPermissions.find((obj) => {
                return obj === permission;
            }) ? (
                children
            ) : (
                isFull
                    ?
                    userPermissions.length
                        ?
                        <Navigate to="/user/denied" />
                        :
                        <></>
                    :
                    <></>
            )}
        </>
    );
}