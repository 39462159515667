import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import CatchyCheckbox from "../CatchyCheckbox";
import Infobox from "../Infobox";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { convertPerToEngDigits } from "src/utils/convertPerToEngDigits";
var moment = require('jalali-moment');

export default function FormikField({
    label,
    placeholder,
    inputName,
    info,
    errors,
    setFieldValue,
    values,
    options,
    disabled = false,
    additionalAction = () => { },
    type = "text",
}) {
    switch (type) {
        case "text":
            return (
                <div className="flex flex-col gap-2 relative">
                    <label htmlFor={inputName}>{label}</label>
                    <Field
                        className={`p-2 py-3 rounded-xl border border-solid ${errors[inputName] ? "border-red-500" : "border-gray-300"
                            } placeholder-sm:text-sm`}
                        type="text"
                        placeholder={placeholder}
                        name={inputName}
                    />
                    {info && (
                        <div className="flex justify-end items-center w-[20px] absolute top-[45px] left-[15px]">
                            <Infobox text={info} />
                        </div>
                    )}
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        case "number":
            return (
                <div className="flex flex-col gap-2 relative">
                    <label htmlFor={inputName}>{label}</label>
                    <Field
                        className={`p-2 py-3 rounded-xl border border-solid ${errors[inputName] ? "border-red-500" : "border-gray-300"
                            } placeholder-sm:text-sm`}
                        type="number"
                        placeholder={placeholder}
                        name={inputName}
                        min={0}
                    />
                    {info && (
                        <div className="flex justify-end items-center w-[20px] absolute top-[45px] left-[15px]">
                            <Infobox text={info} />
                        </div>
                    )}
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        case "checkbox":
            return (
                <div
                    className="flex flex-col gap-2"
                    style={{ justifyContent: "flex-end" }}
                >
                    <CatchyCheckbox
                        name={inputName}
                        handleCheck={() => {
                            setFieldValue(inputName, !values[inputName]);
                        }}
                        isActive={values[inputName]}
                        label={label}
                    />
                </div>
            );
        case "file":
            return (
                <div className="flex flex-col gap-2 relative">
                    <label htmlFor={inputName}>{label}</label>
                    <label
                        htmlFor={inputName}
                        className={`flex items-center justify-between border ${errors[inputName] ? "border-red-500" : "border-gray-300"
                            } border-solid rounded-xl px-2 py-1 text-gray-400`}
                    >
                        <span>{placeholder}</span>
                        <span className="cursor-pointer text-white bg-primary-color px-3 py-2 rounded-xl">
                            انتخاب فایل
                        </span>
                    </label>
                    <Field
                        className="hidden p-2 py-3 rounded-xl border border-solid border-gray-300 placeholder-sm:text-sm"
                        type="file"
                        name={inputName}
                        id={inputName}
                    />
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        case "textarea":
            return (
                <div className="relative flex flex-col gap-2 col-span-2">
                    <label className="flex gap-1 items-center" htmlFor={inputName}>
                        {label}
                        {/* <span className="text-[#7C7C7C] text-sm">(اختیاری)</span> */}
                    </label>
                    <Field
                        className={`p-2 pb-20 rounded-xl border border-solid ${errors[inputName] ? "border-red-500" : "border-gray-300"
                            } w-full xl:placeholder-text-xs`}
                        type="textarea"
                        placeholder={placeholder}
                        name={inputName}
                    />
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        case "select":
            return (
                <div className="flex flex-col gap-2 relative">
                    <label htmlFor={inputName}>{label}</label>
                    <Select
                        name={inputName}
                        value={values[inputName]
                            ? options.map((item) => {
                                if (values[inputName] === item.value) {
                                    return item;
                                }
                            })
                            : null}
                        className="al-dashboard"
                        // isMulti
                        isClearable
                        isDisabled={disabled}
                        placeholder={placeholder}
                        options={options}
                        components={{
                            IndicatorSeparator: () => <div className="relative"><Infobox text={info} /></div>,
                        }}
                        onChange={(selectedOption) => {
                            setFieldValue(
                                inputName,
                                selectedOption ? selectedOption.value : null
                            )
                            additionalAction(selectedOption)
                        }}
                    />
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        case "multiselect":
            return (
                <div className="flex flex-col gap-2">
                    <label htmlFor={inputName}>{label}</label>
                    <Select
                        name={inputName}
                        value={values[inputName]
                            ? options.map((item) => {
                                if (Array.isArray(values[inputName]) && values[inputName].includes(item.value)) {
                                    return item;
                                }
                            })
                            : null}
                        className="al-dashboard"
                        isMulti
                        isClearable
                        placeholder={placeholder}
                        options={options}
                        components={{
                            IndicatorSeparator: () => <Infobox text={info} />,
                        }}
                        onChange={(selectedOption) => {
                            setFieldValue(
                                inputName,
                                selectedOption.map(function (single) {
                                    return single.value;
                                })
                            );
                        }}
                    />
                </div>
            );
        case "datepicker":
            return (
                <div className="flex flex-col gap-2 w-full relative">
                    <label
                        htmlFor={inputName}
                    >
                        {label}{" "}
                    </label>
                    <DatePicker
                        style={{ width: "100%", padding: "24px 15px" }}
                        format="YYYY/MM/DD HH:mm"
                        plugins={[
                            <TimePicker
                                hideSeconds
                            />,
                        ]}
                        minDate={new Date()}
                        calendar={persian}
                        locale={persian_fa}
                        value={values[inputName]}
                        onChange={(value) => {
                            setFieldValue(inputName, convertPerToEngDigits(value.format("YYYY/MM/DD HH:mm")));
                        }}
                        placeholder={placeholder}
                    />
                    {info && (
                        <div className="flex justify-end items-center w-[20px] absolute top-[45px] left-[15px]">
                            <Infobox text={info} />
                        </div>
                    )}
                    <div className="text-red-600 text-sm absolute top-[82px]">
                        <ErrorMessage className="text-red-500" name={inputName} />
                    </div>
                </div>
            );
        default:
            break;
    }
}
