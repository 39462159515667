export function tinymceTextFormatter(rawText) {
    // Step 1: Remove the first <p> tag
    let formattedText = rawText.slice(3); // Assuming the first character is always '<'

    // Step 2: Replace </p><p> with '\n'
    formattedText = formattedText.replace(/<\/?p>/g, ""); // This removes both opening and closing p tags

    // Step 3: Remove the last </p> tag
    if (formattedText.endsWith("</p>")) {
        let lastIndex = formattedText.lastIndexOf("</p>");
        formattedText = formattedText.slice(0, lastIndex);
    }

    // Step 4: Replace <br> with '\n'
    formattedText = formattedText.replace(/<br\s*\/?>/g, "\n");
    return formattedText;
}
