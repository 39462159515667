import { notify } from "src/services/Notification/Notification";

export function validate(values, campType, messageType, text) {
    let isValid = true;
    if (!values.name) {
        notify("لطفا فیلد نام پیام را وارد کنید", "error");
        isValid = false;
    }

    // validate campaign and related
    if (!values.campaignId) {
        notify("لطفا کمپینی که پیام به آن متعلق است را انتخاب کنید", "error");
        isValid = false;
    } else {
        if (campType == "fixed") {
            if (!values.messageTime) {
                notify("لطفا زمان ارسال پیام را مشخص کنید", "error");
                isValid = false;
            }
            if (values.leastDay === "") {
                notify(
                    "لطفا مشخص کنید حداقل چند روز از عضویت افراد گذشته باشد تا این پیام برای آن‌ها ارسال شود",
                    "error"
                );
                isValid = false;
            }
        } else if (campType == "camp") {
            console.log(values.day);
            if (values.day === "") {
                notify("لطفا روز را وارد کنید", "error");
                isValid = false;
            }
            if (values.hour === "") {
                notify("لطفا ساعت را وارد کنید", "error");
                isValid = false;
            }
            if (values.minute === "") {
                notify("لطفا دقیقه را وارد کنید", "error");
                isValid = false;
            }
            if (values.second === "") {
                notify("لطفا ثانیه را وارد کنید", "error");
                isValid = false;
            }
        }
    }

    // validate pattern
    if (messageType == "pattern") {
        if (!values.patternCode) {
            notify("لطفا کد پترن را وارد کنید", "error");
            isValid = false;
        }
    }

    // validate text
    if (messageType == "text") {
        if (!text) {
            notify("لطفا متن پیام را وارد کنید", "error");
            isValid = false;
        }
    }

    return isValid;
}
