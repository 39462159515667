import { useContext } from "react";
import Divider from "src/components/Divider";
import ProfileBox from "src/components/ProfileBox";
import userContext from "src/context/userContext";

export default function SidebarHeader() {
    const { user } = useContext(userContext)
    return (
        <>
            <div className="flex flex-col items-center justify-between flex-shrink-0 p-2 mt-4 lg:mt-[52px]">
                {/* <span className="flex p-2 text-xl font-semibold leading-8 tracking-wider whitespace-nowrap">
                <img src="assets/img/logo.png" className="w-10" />
                <span className="mr-2">iritco</span>
            </span> */}
                <ProfileBox title={user.displayName} subTitle={`نام کاربری: ${user.email}`} type={1} />
            </div>
            <Divider width={70} space={10} height={1} />
        </>
    )
}