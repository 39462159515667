import HeaderSection from "src/components/HeaderSection";
import QuickAccessBox from "src/components/QuickAccessBox";

export default function Home() {
  return (
    <>
      {/* <HeaderSection title="دسترسی سریع" /> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <QuickAccessBox
          title="افزودن مخاطب"
          subTitle="افزودن مخاطب جدید"
          to="/app/contact-management/contact/add"
        />
        <QuickAccessBox
          title="افزودن گروه"
          subTitle="افزودن گروه جدید"
          to="/app/contact-management/group/add"
        />
        <QuickAccessBox
          title="افزودن پیام"
          subTitle="افزودن پیام جدید"
          to="/app/message-management/message/add"
        />
        <QuickAccessBox
          title="گزارش ارسال‌ها"
          subTitle="مشاهده گزارش ارسال پیام‌ها"
          to="/app/message-management/report"
        />
      </div>
    </>
  );
}
