import { Field } from "formik";
import { useState, useCallback, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import { HiOutlineTrash } from "react-icons/hi2";
import Select from "react-select";
import { produce } from "immer";
import { get } from "src/services/HttpClient";

export default function PatternMessage({ vars, setVars }) {
    const [fieldOptions, setFieldOptions] = useState()

    useEffect(() => {
        getFields();
    }, []);

    async function getFields() {
        const result = await get("/contacts/fields");
        if (result?.body) {
            setFieldOptions(result?.body?.map(function (single) {
                return {
                    value: single.name,
                    label: single.nameFa
                }
            }))
        }
    }

    const handleAdd = () => {
        setVars(
            [
                ...vars,
                {
                    name: "",
                    value: "",
                    altValue: ""
                }
            ]
        )
    }

    const handleDelete = (selectedIndex) => {
        let newVars = []
        newVars = vars.filter(function (single, index) {
            return selectedIndex != index
        })
        setVars(newVars)
    }

    const handleChange = useCallback((e, selectedIndex, key) => {
        setVars(
            produce((draft) => {
                const findedVar = draft.find((todo, index) => index === selectedIndex);
                if (key == "value") {
                    findedVar[key] = e.value;
                } else {
                    findedVar[key] = e.target.value;
                }
            })
        );
    }, [])

    useEffect(() => {
        console.log(vars);
    }, [vars])


    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <span>کد پترن</span>
                <Field
                    name="patternCode"
                    type="text"
                    className="min-w-[50%] lg:w-[50%] p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                    placeholder="کد پترن دریافتی از پنل پیامکی را وارد کنید"
                />
            </div>
            <div className="flex flex-col gap-3">
                {
                    vars?.map(function (single, index) {
                        return (
                            <>
                                <div className="bg-gray-100 h-[1px] w-2/3"></div>
                                <div className="flex gap-3">
                                    <input
                                        value={single.name}
                                        onChange={(e) => handleChange(e, index, "name")}
                                        type="text"
                                        className="p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                        placeholder="متغیر"
                                    />
                                    <Select
                                        value={fieldOptions?.map((item) => {
                                            if (single.value === item.value) {
                                                return item;
                                            }
                                        })}
                                    onChange={(e) => handleChange(e, index, "value")}
                                    className="al-dashboard w-[250px]"
                                    isClearable
                                    placeholder="مقدار"
                                    options={fieldOptions}
                                    />
                                    <input
                                        value={single.altValue}
                                        onChange={(e) => handleChange(e, index, "altValue")}
                                        name="altValue"
                                        type="text"
                                        className="p-2 py-3 rounded-xl border border-solid placeholder-sm:text-sm"
                                        placeholder="مقدار جایگزین"
                                    />
                                    <div
                                        className="cursor-pointer select-none inline-flex items-center justify-center gap-1 text-sm px-4 py-3 rounded-lg bg-red-50 hover:bg-red-100 text-red-500 hover:text-red-600 transition-all ease-in-out duration-200"
                                        onClick={(e) => handleDelete(index)}
                                    >
                                        <HiOutlineTrash size={20} />
                                        <span>حذف متغیر</span>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
            <div>
                <div
                    className="cursor-pointer select-none inline-flex items-center justify-center gap-1 px-4 py-3 rounded-lg bg-green-50 hover:bg-green-100 text-green-500 hover:text-green-600 transition-all ease-in-out duration-200"
                    onClick={handleAdd}
                >
                    <GoPlus />
                    <span>افزودن متغیر</span>
                </div>
            </div>

        </div>
    )
}