import { useEffect, useState } from 'react';
import Table from 'src/components/Table'
import { get } from 'src/services/HttpClient';
import { PiEyeDuotone } from "react-icons/pi";
import { PiTrashDuotone } from "react-icons/pi";
import { PiPencilDuotone } from "react-icons/pi";
import PageHeader from 'src/components/PageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetCurrentURL from 'src/utils/useGetCurrentURL';
import { milisecondsToDhms } from 'src/utils/milisecondsToDhms';
import DeleteModal from 'src/components/DeleteModal';
var moment = require('jalali-moment');


export default function Messages() {
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const [reload, setReload] = useState(false)
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const cid = params.get("cid");

    const columns = [
        // {
        //     id: "id",
        //     cell: info => info.getValue(),
        //     header: "شناسه",
        //     footer: info => info.column.id,
        // },
        {
            id: "name",
            cell: info => info.getValue(),
            header: "عنوان",
            footer: info => info.column.name,
        },
        {
            id: "type",
            cell: info => info.getValue(),
            header: "نوع پیام",
            footer: info => info.column.description,
        },
        {
            id: "messageTime",
            cell: info => {
                if (info.row.original.campaign.type == "camp") {
                    return milisecondsToDhms(info.getValue())
                } else if (info.row.original.campaign.type == "fixed") {
                    // moment.unix only works with seconds
                    return moment.unix(info.getValue() / 1000).locale('fa').format('YYYY/MM/DD HH:mm:ss');
                }
            },
            header: "زمان ارسال",
            footer: info => info.column.description,
        },
        {
            id: "campaign",
            cell: info => info.getValue().name,
            header: "نام کمپین",
            footer: info => info.column.description,
        },
        {
            id: "campaign",
            cell: info => {
                if (info.getValue().type == "camp") {
                    return "زمان محور"
                } else if (info.getValue().type == "fixed") {
                    return "زمان ثابت"
                }
            },
            header: "نوع کمپین",
            footer: info => info.column.description,
        },
        {
            id: "id",
            cell: info => (
                <div className='flex gap-2'>
                    {/* <div className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'>
                        <PiPencilDuotone className='' />
                    </div> */}
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            setSelectedRow(info.getValue())
                            setActive(true)
                        }}
                    >
                        <PiTrashDuotone className='' />
                    </div>

                </div >
            ),
            header: "عملیات",
            footer: info => info.column.id,
            enableColumnFilter: false
        },
    ];

    useEffect(() => {
        getData();
    }, [reload]);

    async function getData() {
        let result;
        if (cid) {
            result = await get(`/campaigns/${cid}/messages`);
        } else {
            result = await get(`/messages`);
        }
        if (result && result.success) {
            setData(result.body)
        }
    }

    return (
        <>
            <PageHeader
                title="مدیریت پیام‌ها"
                btnTitle="افزودن پیام جدید"
                handleClick={() => {
                    if (cid) {
                        navigate(pathname + `/add/?cid=${cid}`);
                    } else {
                        navigate(pathname + `/add`);
                    }
                }}
            />
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف پیام"
                id={selectedRow}
                url="/messages"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    )
}