import { post } from "src/services/HttpClient";
import { tinymceTextFormatter } from "src/utils/tinymceTextFormatter";

var moment = require("jalali-moment");

export async function submit(values, campType, messageType, messageText, vars) {
    let messageTime;
    let text;

    // campType
    if (campType == "camp") {
        // duration in second
        messageTime =
            (values.day * 86400 +
                values.hour * 3600 +
                values.minute * 60 +
                values.second) *
            1000;
    } else if (campType == "fixed") {
        // timestamp in second
        messageTime = Date.parse(
            moment
                .from(values.messageTime, "fa", "YYYY/MM/DD HH:mm")
                .format("YYYY/MM/DD HH:mm")
        );
    }

    // messageType
    if (messageType == "text") {
        text = tinymceTextFormatter(messageText);
    } else if (messageType == "pattern") {
        text = JSON.stringify({
            patternCode: values.patternCode,
            vars,
        });
    }
    const result = await post("/messages", {
        name: values.name,
        type: messageType,
        text,
        messageTime,
        leastDay: values.leastDay || null,
        campaignId: values.campaignId,
    });
}
