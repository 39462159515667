import { useEffect, useState } from 'react';
import Table from 'src/components/Table'
import { get } from 'src/services/HttpClient';
import { PiEyeDuotone } from "react-icons/pi";
import { PiTrashDuotone } from "react-icons/pi";
import { PiPencilDuotone } from "react-icons/pi";
import PageHeader from 'src/components/PageHeader';
import { useNavigate } from 'react-router-dom';
import useGetCurrentURL from 'src/utils/useGetCurrentURL';
import DeleteModal from 'src/components/DeleteModal';
import { getData } from 'src/actions/getData';



export default function Groups() {
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const pathname = useGetCurrentURL();

    const columns = [
        // {
        //     id: "id",
        //     cell: info => info.getValue(),
        //     header: "شناسه",
        //     footer: info => info.column.id,
        // },
        {
            id: "name",
            cell: info => info.getValue(),
            header: "عنوان",
            footer: info => info.column.name,
        },
        {
            id: "description",
            cell: info => info.getValue(),
            header: "توضیحات",
            footer: info => info.column.description,
        },
        {
            id: "id",
            cell: info => (
                <div className='flex gap-2'>
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            navigate(
                                `/app/contact-management/group/edit?id=${info.getValue()}`
                            );
                        }}
                    >
                        <PiPencilDuotone className='' />
                    </div>
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            setSelectedRow(info.getValue())
                            setActive(true)
                        }}
                    >
                        <PiTrashDuotone className='' />
                    </div>

                </div>
            ),
            header: "عملیات",
            footer: info => info.column.id,
            enableColumnFilter: false
        },
    ];

    useEffect(() => {
        getData(setData, "/groups");
    }, [reload]);


    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title="گروه‌ها"
                btnTitle="افزودن گروه جدید"
                handleClick={() => {
                    navigate(pathname + "/add");
                }}
            />
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف گروه"
                id={selectedRow}
                url="/groups"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    )
}