import React, { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useState } from "react";

const TextEditor = ({ value, handleOnChange, setDescriptionIsShort }) => {
  // -----------------------------states:
  const [text, setText] = useState(value);

  // -----------------------------initValues:
  const initValues = {
    selector: "textarea",
    // placeholder: "متن پیام را وارد کنید ...",
    height: 300,
    language: "fa",
    directionality: 'rtl',
    forced_root_block: false,
    content_style:
      "@import url('../../../fonts/ir_sans.ttf'); body {font-family:'IRANSans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; font-size:14px }",
    // menubar: "file edit insert view format table tools help",
    menubar: false,
    // toolbar:
    //   "redo undo fontfamily | blocks | " +
    //   "bold italic forecolor | alignleft aligncenter " +
    //   "alignright alignjustify | bullist numlist outdent indent | link image media " +
    //   "removeformat | help",
    toolbar:
      "redo undo",

    font_family_formats:
      "IRANSans='IRANSans','Segoe UI', 'Roboto'; " +
      "Arial=arial,helvetica,sans-serif; " +
      "Arial Black=arial black,avant garde; " +
      "Comic Sans MS=comic sans ms,sans-serif; " +
      "Helvetica=helvetica; Impact=impact,chicago; " +
      "Tahoma=tahoma,arial,helvetica,sans-serif;",

    // plugins: [
    //   "advlist",
    //   "autolink",
    //   "lists",
    //   "link",
    //   "image",
    //   "charmap",
    //   "preview",
    //   "anchor",
    //   "searchreplace",
    //   "visualblocks",
    //   "fullscreen",
    //   "insertdatetime",
    //   "media",
    //   "table",
    //   "code",
    //   "help",
    //   "wordcount",
    // ],
    plugins: [
      "wordcount",
    ],
  };

  // -----------------------------useEffect:
  useEffect(() => {
    if (text?.length < 5) {
      // setDescriptionIsShort(true);
    } else {
      // setDescriptionIsShort(false);
    }
  }, [text]);

  // -----------------------------return:
  return (
    <>
      <Editor
        value={value}
        newValue={value}
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.2.1/tinymce.min.js"
        // initialValue={`<p>${value}</p>`}
        onEditorChange={(newValue, editor) => {
          setText(editor.getContent({ format: "text" }));
          handleOnChange(newValue);
        }}
        init={initValues}
      />
    </>
  );
};

export default TextEditor;
