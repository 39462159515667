import { notify } from "src/services/Notification/Notification";
import Modal from "../Modal";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";
import ModalHeader from "../Modal/ModalHeader";
import TwinButton from "../TwinButton";
import { remove } from "src/services/HttpClient/HttpClient";

export default function DeleteModal({ title, url, id, active, setActive, additionalAction = () => { } }) {
    return (
        <Modal isOpen={active} width={500}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <div className="p-4">آیا از انجام این عملیات اطمینان دارید؟</div>
            </ModalBody>
            <ModalFooter>
                <TwinButton
                    isCompact={true}
                    rejectHandle={() => setActive(false)}
                    rejectTitle="بستن"
                    acceptHandle={async () => {
                        const result = await remove(url, {
                            params: {
                                id,
                            },
                        });
                        setActive(false);
                        additionalAction();
                        if (result && result.success) {
                            notify(result.message);
                        }
                    }}
                    acceptTitle="بله"
                />
            </ModalFooter>
        </Modal>
    )
}