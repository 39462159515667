import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikField from "src/components/FormikField/FormikField";
import { get, post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import { useNavigate } from "react-router-dom";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
import TwinButton from "src/components/TwinButton";
import { useEffect, useState } from "react";

export default function AddContact() {
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    const [groupOptions, setGroupOptions] = useState([])

    const getOrgs = async () => {
        let result = await get("/groups");
        if (result.body) {
            setGroupOptions(result.body.map(function (single) {
                return {
                    value: single.id,
                    label: single.name
                }
            }))
        }
    }
    useEffect(() => {
        getOrgs();
    }, [])

    return (
        <>
            <PageHeader
                title="افزودن مخاطب جدید"
            />
            <WhiteBoard>
                <Formik
                    initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        username: "",
                        phone: "",
                        groupId: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            const result = await post("/contacts", {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                username: values.username,
                                phone: values.phone,
                                groupId: values.groupId,
                            });
                            if (result && result.success) {
                                notify(result.message)
                                navigate(pathname.slice(0, pathname.search("/add")));
                            } else {
                                throw new Error();
                            }
                        } catch (error) {
                            notify("خطایی رخ داده است", "error")
                        }
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl p-10 px-20">
                                <div className="flex gap-10 mt-10">
                                    <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="firstName"
                                            label="نام (اختیاری)"
                                            placeholder="نام مخاطب را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="lastName"
                                            label="نام خانوادگی (اختیاری)"
                                            placeholder="نام خانوادگی مخاطب را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="email"
                                            label="ایمیل (اختیاری)"
                                            placeholder="ایمیل مخاطب را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="username"
                                            label="نام کاربری (اختیاری)"
                                            placeholder="نام کاربری مخاطب را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="phone"
                                            label="موبایل (اختیاری)"
                                            placeholder="موبایل مخاطب را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            type="multiselect"
                                            inputName="groupId"
                                            label="گروه (اختیاری)"
                                            placeholder="گروه‌های مخاطب را وارد کنید..."
                                            info="گروه‌هایی که مخاطب به آن‌ها متعلق است را مشخص کنید"
                                            errors={errors}
                                            options={groupOptions}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                                <TwinButton
                                    acceptTitle="افزودن مخاطب جدید"
                                    rejectHandle={
                                        () => {
                                            navigate(pathname.slice(0, pathname.search("/add")));
                                        }
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </WhiteBoard>
        </>
    )
}