import { useEffect, useState } from 'react';
import Table from 'src/components/Table'
import { PiEyeDuotone } from "react-icons/pi";
import { PiTrashDuotone } from "react-icons/pi";
import { PiPencilDuotone } from "react-icons/pi";
import PageHeader from 'src/components/PageHeader';
import { useNavigate } from 'react-router-dom';
import useGetCurrentURL from 'src/utils/useGetCurrentURL';
import DeleteModal from 'src/components/DeleteModal';
import { getData } from 'src/actions/getData';
var moment = require("jalali-moment");


export default function Campaigns() {
    const [data, setData] = useState()
    const [active, setActive] = useState(false)
    const [reload, setReload] = useState(false)
    const [selectedRow, setSelectedRow] = useState()
    const pathname = useGetCurrentURL();
    const navigate = useNavigate();

    useEffect(() => {
        getData(setData, "/campaigns", {
            params: {
                type: "camp"
            }
        })
    }, [reload]);

    const columns = [
        // {
        //     id: "id",
        //     cell: info => info.getValue(),
        //     header: "شناسه",
        //     footer: info => info.column.id,
        // },
        {
            id: "name",
            cell: info => info.getValue(),
            header: "عنوان",
            footer: info => info.column.firstName,
        },
        {
            id: "description",
            cell: info => info.getValue(),
            header: "توضیحات",
            footer: info => info.column.lastName,
        },
        {
            id: "groups",
            cell: info => info.getValue().map((item) => item.name).toString(),
            header: "گروه‌ها",
            footer: info => info.column.lastName,
        },
        {
            id: "createdAt",
            cell: (info) => (
                <>
                    {info.getValue()
                        ? moment
                            .from(info.getValue(), "en", "YYYY-MM-DD HH:mm:ss")
                            .locale("fa")
                            .format("jYYYY/jMM/jDD HH:mm")
                        : "---"}
                </>
            ),
            header: "زمان ایجاد",
            footer: info => info.column.email,
        },
        {
            id: "id",
            cell: info => (
                <div className='flex gap-2'>
                    <div
                        className='cursor-pointer hover:bg-blue-100 hover:text-blue-600 transition-all ease-in-out duration-300 bg-blue-50 text-blue-500 p-2 rounded-lg'
                        onClick={() => navigate(`/app/message-management/message/?cid=${info.getValue()}`)}
                    >
                        <span className='flex justify-center items-center'>
                            مدیریت پیام‌ها
                        </span>
                    </div>
                    <div
                        className='cursor-pointer hover:bg-gray-100 hover:text-gray-600 transition-all ease-in-out duration-300 bg-gray-50 text-gray-400 p-2 rounded-lg'
                        onClick={() => {
                            setSelectedRow(info.getValue())
                            setActive(true)
                        }}
                    >
                        <PiTrashDuotone className='' />
                    </div>
                </div>
            ),
            header: "عملیات",
            footer: info => info.column.id,
            enableColumnFilter: false
        },
    ];

    return (
        <>
            <PageHeader
                title="مدیریت کمپین‌های زمان محور"
                btnTitle="افزودن کمپین جدید"
                handleClick={() => {
                    navigate(pathname + "/add")
                }}
            />
            <Table data={data} columns={columns} />
            <DeleteModal
                title="حذف کمپین"
                id={selectedRow}
                url="/campaigns"
                active={active}
                setActive={setActive}
                additionalAction={() => { setReload(!reload) }}
            />
        </>
    )
}