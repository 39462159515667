export default function ProfileBox({ imgSrc, title, subTitle, type = 2 }) {


    switch (type) {
        case 1:
            return (
                <div className="w-full">
                    <div className="flex flex-col gap-3 justify-center items-center p-4 bg-[#f5f8fa] dark:bg-gray-800 rounded-3xl">
                        <img
                            style={{ width: "55px", borderRadius: "50%" }}
                            alt="Profile"
                            src={imgSrc ? imgSrc : "/assets/img/icons/holder.webp"}
                        />
                        <div className="flex flex-col justify-center gap-1">
                            <span className='flex justify-center text-base font-medium'>
                                {title}
                            </span>
                            <span className='flex justify-center text-xs rounded-md items-center pt-[5px]'>
                                {subTitle}
                            </span>
                        </div>
                    </div>
                </div>
            )
        case 2:
            return (
                <div className='w-full relative z-1'>
                    <div className="flex flex-col p-4 gap-2 bg-[#f5f8fa] rounded-3xl relative z-[2]">
                        <div className='flex gap-3 items-center w-full'>
                            <img
                                style={{ width: "55px", borderRadius: "50%" }}
                                alt="Profile"
                                src={imgSrc ? imgSrc : "/assets/img/icons/holder.webp"}
                            />
                            <div className='flex flex-col text-gray-600'>
                                <span className='text-base font-medium'>
                                    {title}
                                </span>
                                <span className='text-xs rounded-md flex justify-center items-center pt-[5px]'>
                                    {subTitle}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        default:
            break;
    }


}
