import { useContext, useState } from "react";
import Button from "./Button";
import Modal from "src/components/Modal";
import ModalHeader from "src/components/Modal/ModalHeader";
import ModalBody from "src/components/Modal/ModalBody";
import ModalFooter from "src/components/Modal/ModalFooter";
import { defaultHeaderHeight } from "src/settings/config";
import userContext from "src/context/userContext";

export default function AvatarButton({ direction }) {
    const [active, setActive] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem("access_token");
        window.location.href = "/";
    }
    const { user } = useContext(userContext)

    return (
        <div className="relative">
            <Button isActive={active} toggle={() => setActive(true)} />
            <Modal
                isOpen={active}
                toggle={() => setActive(false)}
                top={defaultHeaderHeight}
                width={300}
                left={direction == "rtl" && 10}
                right={direction != "rtl" && 10}
            >
                {/* <ModalHeader toggle={() => setActive(false)} >
                    salam
                </ModalHeader> */}
                <ModalBody>
                    <div className="flex flex-col p-4 space-y-1 font-medium border-b">
                        <span className="flex gap-1 items-center text-gray-800">
                            <span>{user.displayName}</span>
                            {/* <span className="text-xs text-gray-600">سوپرادمین</span> */}
                        </span>
                        <span className="text-sm text-gray-400">
                            {user.email}
                        </span>
                    </div>
                    {/* <ul className="flex flex-col p-2 my-2 space-y-1">
                        <li>
                            <a href="#" className="block px-2 py-1 transition rounded-md hover:bg-gray-100">
                                Link
                            </a>
                        </li>
                        <li>
                            <a href="#" className="block px-2 py-1 transition rounded-md hover:bg-gray-100">
                                Another Link
                            </a>
                        </li>
                    </ul> */}
                    <div
                        className="cursor-pointer mt-4 flex items-center justify-center p-3 text-red-600 bg-red-50 rounded-lg"
                        onClick={handleLogout}
                    >
                        <span>خروج</span>
                    </div>
                </ModalBody>
                {/* <ModalFooter>
                    <div
                        onClick={() => { setActive(false) }}
                    >
                        ذخیره تغییرات
                    </div>
                </ModalFooter> */}
            </Modal>
        </div>
    )
}