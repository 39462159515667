import PageHeader from "src/components/PageHeader";
import WhiteBoard from "src/components/WhiteBoard";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikField from "src/components/FormikField/FormikField";
import TwinButton from "src/components/TwinButton";
import HeaderDescriptive from "src/components/HeaderDescriptive";
import { useNavigate } from "react-router-dom";
import { post } from "src/services/HttpClient";
import { notify } from "src/services/Notification/Notification";
import useGetCurrentURL from "src/utils/useGetCurrentURL";
export default function AddGroup() {
    const navigate = useNavigate();
    const pathname = useGetCurrentURL();

    return (
        <>
            <PageHeader
                title="افزودن گروه جدید"
            />
            <WhiteBoard>
                <Formik
                    initialValues={{
                        name: "",
                        description: "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            const result = await post("/groups", {
                                name: values.name,
                                description: values.description,
                            });
                            if (result && result.success) {
                                notify(result.message)
                                navigate(pathname.slice(0, pathname.search("/add")));
                            }
                        } catch (error) {
                            notify("خطایی رخ داده است", "error")
                        }
                    }}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-5 bg-white dark:bg-black rounded-2xl p-10 px-20">
                                <div className="flex gap-10 mt-10">
                                    <div className="flex flex-col xl:grid gap-5 gap-y-9 grid-cols-2  w-full justify-center">
                                        <FormikField
                                            inputName="name"
                                            label="عنوان"
                                            placeholder="عنوان گروه را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                        <FormikField
                                            inputName="description"
                                            label="توضیحات"
                                            placeholder="توضیحات گروه را وارد کنید..."
                                            // info="بصورت رشته ای است و نام سازمان را مشخص می کند"
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <TwinButton
                                    acceptTitle="افزودن گروه جدید"
                                    rejectHandle={
                                        () => {
                                            navigate(pathname.slice(0, pathname.search("/add")));
                                        }
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </WhiteBoard>
        </>
    )
}