import { adminRoot } from "./config";
import { HiOutlineClipboardList, HiOutlineNewspaper } from "react-icons/hi";
import { VscSettings } from "react-icons/vsc";
import { BsDatabase } from "react-icons/bs";
import { LuBrainCircuit, LuLayoutDashboard } from "react-icons/lu";
import { RiHistoryLine } from "react-icons/ri";
import { PiCubeDuotone } from "react-icons/pi";
import { PiTreeStructureDuotone } from "react-icons/pi";
import { AiTwotoneSetting } from "react-icons/ai";
import PublicView from "src/views/PublicView";
import Denied from "src/views/user/Denied";
import Login from "src/views/user/Login";
import { Outlet } from "react-router-dom";
import ProtectedView from "src/views/ProtectedView";
import Home from "src/views/global/Home";
import { PiPath } from "react-icons/pi";
import Campaigns from "src/views/app/Campaigns/Campaigns";
import Contacts from "src/views/app/Contacts/Contacts";
import { LuContact } from "react-icons/lu";
import { TiContacts } from "react-icons/ti";
import Groups from "src/views/app/Groups/Groups";
import { BsPeople } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { PiGearLight } from "react-icons/pi";
import AddGroup from "src/views/app/Groups/AddGroup";
import AddContact from "src/views/app/Contacts/AddContact";
import { LuAlarmClock } from "react-icons/lu";
import TimedMessages from "src/views/app/TimedMessages/TimedMessages";
import { RiMailSendLine } from "react-icons/ri";
import { TbRoute } from "react-icons/tb";
import { TbMessage2Cog } from "react-icons/tb";
import { PiGearSix } from "react-icons/pi";
import Messages from "src/views/app/Messages/Messages";
import AddMessage from "src/views/app/Messages/AddMessage";
import NotFound from "src/views/errors/NotFound";
import { TbReportAnalytics } from "react-icons/tb";
import EditGroup from "src/views/app/Groups/EditGroup";
import EditContact from "src/views/app/Contacts/EditContact";
import AddTimedMessage from "src/views/app/TimedMessages/AddTimedMessage";
import AddCampaign from "src/views/app/Campaigns/AddCampaign";
import ChangePassword from "src/views/app/Settings/ChangePassword";
import { VscKey } from "react-icons/vsc";
import Ippanel from "src/views/app/Settings/Ippanel";
import Reports from "src/views/app/Messages/Reports";

const menuArray = [
    {
        path: "",
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                path: "",
                element: <Home />,
                permission: "VIEW_PROFILE",
            },
        ],
    },
    {
        icon: <VscSettings size={30} />,
        label: "داشبورد",
        path: `${adminRoot}/dashboard`,
        permission: "VIEW_PROFILE",
        element: <ProtectedView />,
        inMenu: true,
        subs: [
            {
                icon: <LuLayoutDashboard />,
                label: "داشبورد مدیریتی",
                path: `${adminRoot}/dashboard`,
                element: <Home />,
                inMenu: true,
                permission: "VIEW_PROFILE",
            },
        ],
    },
    {
        icon: <TbRoute size={30} />,
        label: "مدیریت کمپین‌ها",
        path: `${adminRoot}/campaign-management`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                icon: <PiPath />,
                label: "کمپین‌های زمان محور",
                inMenu: true,
                path: `${adminRoot}/campaign-management/campaign`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/campaign-management/campaign`,
                        permission: "VIEW_PROFILE",
                        element: <Campaigns />,
                    },
                    ,
                    {
                        label: "افزودن کمپین",
                        inMenu: false,
                        path: `${adminRoot}/campaign-management/campaign/add`,
                        permission: "VIEW_PROFILE",
                        element: <AddCampaign />,
                    },
                ],
            },
            {
                icon: <LuAlarmClock />,
                label: "کمپین‌های زمان ثابت",
                inMenu: true,
                path: `${adminRoot}/campaign-management/timed-message`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/campaign-management/timed-message`,
                        permission: "VIEW_PROFILE",
                        element: <TimedMessages />,
                    },
                    {
                        label: "افزودن کمپین",
                        inMenu: false,
                        path: `${adminRoot}/campaign-management/timed-message/add`,
                        permission: "VIEW_PROFILE",
                        element: <AddTimedMessage />,
                    },
                ],
            },
        ],
    },
    {
        icon: <TbMessage2Cog size={30} />,
        label: "مدیریت پیام‌ها",
        path: `${adminRoot}/message-management`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                icon: <TbMessage2Cog />,
                label: "مدیریت پیام‌ها",
                inMenu: true,
                path: `${adminRoot}/message-management/message`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/message-management/message`,
                        permission: "VIEW_PROFILE",
                        element: <Messages />,
                    },
                    {
                        label: "افزودن پیام",
                        inMenu: false,
                        path: `${adminRoot}/message-management/message/add`,
                        permission: "VIEW_PROFILE",
                        element: <AddMessage />,
                    },
                ],
            },
            {
                icon: <TbReportAnalytics />,
                label: "گزارش ارسال‌ها",
                inMenu: true,
                path: `${adminRoot}/message-management/report`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/message-management/report`,
                        permission: "VIEW_PROFILE",
                        element: <Reports />,
                    },
                ],
            },
        ],
    },
    {
        icon: <TiContacts size={30} />,
        label: "مدیریت مخاطبین",
        path: `${adminRoot}/contact-management`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                icon: <TiContacts />,
                label: "مدیریت مخاطبین",
                inMenu: true,
                path: `${adminRoot}/contact-management/contact`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/contact-management/contact`,
                        permission: "VIEW_PROFILE",
                        element: <Contacts />,
                    },
                    {
                        label: "افزودن مخاطب جدید",
                        inMenu: false,
                        path: `${adminRoot}/contact-management/contact/add`,
                        permission: "VIEW_PROFILE",
                        element: <AddContact />,
                    },
                    {
                        label: "ویرایش مخاطب",
                        inMenu: false,
                        path: `${adminRoot}/contact-management/contact/edit`,
                        permission: "VIEW_PROFILE",
                        element: <EditContact />,
                    },
                ],
            },
            {
                icon: <IoPeopleOutline />,
                label: "مدیریت گروه‌ها",
                inMenu: true,
                path: `${adminRoot}/contact-management/group`,
                permission: "VIEW_PROFILE",
                element: <Outlet />,
                subs: [
                    {
                        inMenu: false,
                        path: `${adminRoot}/contact-management/group`,
                        permission: "VIEW_PROFILE",
                        element: <Groups />,
                    },
                    {
                        label: "افزودن گروه جدید",
                        inMenu: false,
                        path: `${adminRoot}/contact-management/group/add`,
                        permission: "VIEW_PROFILE",
                        element: <AddGroup />,
                    },
                    {
                        label: "ویرایش گروه",
                        inMenu: false,
                        path: `${adminRoot}/contact-management/group/edit`,
                        permission: "VIEW_PROFILE",
                        element: <EditGroup />,
                    },
                ],
            },
        ],
    },
    {
        icon: <PiGearSix size={30} />,
        label: "تنظیمات",
        path: `${adminRoot}/setting`,
        inMenu: true,
        element: <ProtectedView />,
        permission: "VIEW_PROFILE",
        subs: [
            {
                icon: <VscKey />,
                label: "تغییر رمز عبور",
                inMenu: true,
                path: `${adminRoot}/setting/change-password`,
                permission: "VIEW_PROFILE",
                element: <ChangePassword />,
            },
            {
                icon: <VscSettings />,
                label: "تنظیمات پنل پیامک",
                inMenu: true,
                path: `${adminRoot}/setting/ippanel`,
                permission: "VIEW_PROFILE",
                element: <Ippanel />,
            },
        ],
    },
    {
        path: "/user",
        element: <Outlet />,
        subs: [
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "register",
                element: <Login />,
            },
            {
                path: "denied",
                element: <Denied />,
            },
        ],
    },
];
export default menuArray;
